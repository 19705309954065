<template>
  <div class="operator-container">
    <div class="app-main">
      <div class="app-toolbar">
        <div class="app-logo">
          <img alt="Vue logo" :src="logoImg">
        </div>
        <div class="option-btns">
          <el-dropdown @command="handleLogout">
            <span class="el-dropdown-link">
              <el-avatar :size="24" :src="userInfo.avatar" />
              <span class="username">{{ nick_name || user_name }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="password">
                修改密码
              </el-dropdown-item>
              <el-dropdown-item command="logout">
                退出
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="operator-content">
        <h3>早安, Admin, 开心每一天!</h3>
        <el-alert title="平台迁移公告：平台将于本周六 03:00 ~ 04:00 进行迁移，请勿在此期间提交数据。" force-aligned type="warning" show-icon />
        <div class="my-application">
          <div class="top">
            <div class="top-icon">
              <img alt="" src="@/assets/home-app.png">
            </div>
            <div class="top-title">
              我的应用
            </div>
          </div>
          <div class="product-list">
            <div v-for="(item, index) in list" :key="index" class="product-item" @click="gotoapp(item)">
              <div class="product-info">
                <div class="summary">
                  <div class="left">
                    <div class="app-img">
                      <img v-if="item.name === 'CHB'" alt="" src="@/assets/logo-chb.png">
                      <img v-if="item.name === 'OMS'" alt="" src="@/assets/logo-oms.png">
                      <img v-if="item.name === 'CRM'" alt="" src="@/assets/logo-crm.png">
                      <img v-if="item.name === 'SHOP'" alt="" src="@/assets/logo-shop.png">
                    </div>
                  </div>
                  <div class="right">
                    <div class="name">
                      <div>{{ item.name }}</div>
                    </div>
                    <!-- <div class="app-key">
                      介绍：{{ item.introduce }}
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-me">
          <div class="top">
            <div class="top-icon">
              <img alt="" src="@/assets/home-about.png">
            </div>
            <div class="top-title">
              与我相关的
            </div>
            <div class="expand" @click="onExpand">
              <i v-show="expand" class="el-icon-arrow-down" />
              <i v-show="!expand" class="el-icon-arrow-up" />
            </div>
            <div class="more">
              <i class="el-icon-more" />
            </div>
          </div>
          <div class="about-me-content">
            <div v-show="expand" class="message">
              xxx
            </div>
            <div v-show="!expand" class="empty">
              暂无消息
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { useDialog } from "@shopex/finder";
import { getNotice, getNoticeAttach } from '@/api/operator';
export default {
  data () {
    return {
      menuData: [],
      userInfo: {
        avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
      },
      menuPaths: [],
      curPath: '',
      logoImg: require('@/assets/logo.png'),
      list: [
        { name: 'CHB' },
        { name: 'OMS' },
        { name: 'CRM' },
        { name: 'SHOP' },
      ],
      expand: true,
    };
  },
  computed: {
    ...mapState({
      nick_name: state => state.user.nickname,
      user_name: state => state.user.username,
      token: state => state.user.token,
    })
  },
  mounted () {
    this.init();
  },
  methods: {
    async init () {
      const res = await getNotice();
      console.log(res);
    },
    async handleLogout (command) {
      if (command === "password") {
        useDialog(this, {
          title: "修改密码",
          data: [this.token],
          component: () => import("../../main/component/update-password-dialog"),
          actions: [
            { label: "取消", action: "close" },
            { label: "保存", action: "save", type: "primary" },
          ]
        });
      } else {
        const token = this.token;
        let url = process.env.VUE_APP_IFRAME_URL;
        const loginUrl = `${url}/logout?token=${token}&client_id=43d93dab7ebef303`;
        window.location.replace(loginUrl);
      }
    },
    handleClickBreadcrumb (item) {
      if (!item.disable && item.path !== this.$route.path) {
        this.$router.push({ path: item.path });
      }
    },
    gotoapp (item) {
      const domain = window.location.protocol + "//" + window.location.host;
      const appKey = item.appKey;
      window.open(`${domain}/oauth/authorize?response_type=code&client_id=${appKey}&view=ACCOUNT`);
    },
    onExpand () {
      this.expand = !this.expand;
    },
  }
};
</script>
<style lang="scss">
.operator-container {
  .el-alert--warning {
    .el-alert__icon {
      color: #fa8c15;
    }
    .el-alert__closebtn {
      font-size: 14px;
      color: #fa8c15;
    }
  }
}
</style>
<style scoped lang="scss">
.operator-container {
  .app-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .app-logo {
    display: flex;
    align-items: center;
    img {
      height: 25px;
      cursor: pointer;
    }
  }
  .operator-content {
    padding: 16px 28px 13px;
    .my-application {
      margin-top: 43px;
      .top {
        display: flex;
        align-items: center;
        .top-icon {
          margin-right: 12px;
          img {
            height: 19px;
            vertical-align: middle;
          }
        }
        .top-title {
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #001529;
          line-height: 24px;
        }
      }
      .product-list {
        margin-top: 25px;
        margin-right: -25px;
        display: flex;
        flex-wrap: wrap;
        .product-item {
          position: relative;
          margin: 0 25px 25px 0;
          min-width: 280px;
          height: 100px;
          background: #ffffff;
          border-radius: 8px;
          cursor: pointer;
          .product-info {
            position: relative;
          }
          .pro-img {
            width: 100%;
          }
          .summary {
            padding: 20px 24px 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .right {
              margin-left: 12px;
            }
            .name {
              font-size: 17px;
              color: #222;
              font-weight: 500;
            }
            .app-key {
              color: #888;
              margin-top: 4px;
              word-break: keep-all;
            }
            .app-img {
              background-color: #fff;
              display: flex;
              align-items: center;
              width: 60px;
              height: 60px;
              border-radius: 12px;
              padding: 0 4px;
              img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
          .reset-btn {
            margin-top: 15px;
            text-align: right;
          }
        }
        .product-item::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
          opacity: 0;
        }
        .product-item::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }
        .product-item:hover::before {
          opacity: 1;
        }
        .product-item:hover::after {
          opacity: 0;
        }
      }
    }
    .about-me {
      background-color: #fff;
      border-radius: 8px;
      .top {
        display: flex;
        align-items: center;
        height: 57px;
        .top-icon {
          margin-right: 12px;
          img {
            height: 19px;
            vertical-align: middle;
          }
        }
        .top-title {
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #001529;
          line-height: 24px;
        }
        .expand {
          cursor: pointer;
          margin-left: 30px;
          color: #bfbfbf;
        }
        .more {
          margin-left: auto;
          margin-right: 32px;
          width: 20px;
          height: 20px;
          background: #f2fbff;
          border-radius: 4px;
          color: #1890ff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  .el-alert--warning {
    background: #fff7e6;
    border-radius: 4px;
    border: 1px solid #ffd591;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    line-height: 20px;
  }
}
</style>
