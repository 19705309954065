import fetch from './req';

/**
 * 加载当前用户公告信息
*/
export function getNotice (query = {}) {
  return fetch({
    url: '/auth/notice/load',
    method: 'get',
    params: query
  });
}

/**
 * 根据公告ID获取当前公告附件
*/
export function getNoticeAttach (query = {}) {
  return fetch({
    url: '/auth/notice/getNoticeAttach',
    method: 'get',
    params: query
  });
}